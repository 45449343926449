// src/pages/NotFound.js
import React from 'react';
import Container from '@mui/material/Container';
import MoodBadIcon from '@mui/icons-material/MoodBad';

const NotFound = () => {
    return (
        <>

<Container maxWidth="xl">
        <div className='w-full flex flex-col gap-4 items-center text-center justify-center'>
    
            
            <div className='flex flex-col gap-4 text-center w-full justify-center items-center'>
        
            <h1 className='text-4xl font-bold text-red-600'> <MoodBadIcon className='scale-150' /> Not Found - 404 <MoodBadIcon className='scale-150' /></h1> 
              <p className='text-xl'>The requested page cannot be founded!</p>
            </div>

          
            
        </div>
        </Container>
      </>
    );
};

export default NotFound;
