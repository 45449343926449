import React from 'react'
import { Link } from 'react-router-dom';

const PythonCourse = () => {
    const pythonImage = "https://raw.githubusercontent.com/officialkasun/prolearnerslk/refs/heads/main/assets/python.png";
    return (
        <div className="max-w-xs w-96 mx-auto bg-base-100 rounded-lg shadow-lg overflow-hidden p-6 space-y-4 hover:translate-y-[-10px] ">
          <div className="flex items-center justify-center">
            {pythonImage ? (
              <img
                src={pythonImage}
                alt="Python"
                className="w-32  p-2 object-cover border-4 border-indigo-600"
              />
            ) : (
              <div className="skeleton w-24 h-24 rounded-full bg-base-300 animate-pulse" />
            )}
          </div>
          <div className="text-center">
            <h2 className="text-xl font-semibold text-base-content">Core Python Gen-Z (සිංහල)</h2>
            <p className="text-gray-500 mt-2">[Learn Python from Scratch to Advanced] - මුල සිට අග දක්වා සරළව සිංහලෙන්</p>
          </div>
          <div className="flex justify-center">
          <Link to={'/courses/python'}  className="btn mt-4 bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition duration-200">
              View Course
            </Link>
          </div>
        </div>
      );
}

export default PythonCourse