import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Fade from '@mui/material/Fade';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './App.css';

import Navbar from './components/navbar';
import Home from './pages/Home';
import About from './pages/About';
import NotFound from './pages/NotFound';
import Footer from './components/footer';
import Python from './pages/courses/Python';

// ScrollTop component handles showing/hiding the button and scroll action
function ScrollTop(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Fade  in={trigger}>
      <Box 
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        
      >
        {children}
      </Box>
    </Fade>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

// BackToTop component wraps the main content and includes the ScrollTop button
export default function BackToTop(props) {
  return (
    <Router>

     
      <React.Fragment>
        <CssBaseline />
        
        {/* AppBar with Navbar */}
        <AppBar className='select-none'>
        
            <Navbar />
      
        </AppBar>

        <Navbar /> {/* Navbar remains fixed */}
        
        
     
        <div className='select-none' style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <div className='mb-20' style={{ flex: 1, overflowY: 'auto' }}>
        {/* Main Content */}
        <Toolbar id="back-to-top-anchor" />
        <Container>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/courses/python" element={<Python />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Container>
      </div>

      <div style={{ position: 'relative', bottom: 0, width: '100%' }}>
        <Footer />
      </div>
    </div>
        {/* ScrollTop Button */}
        <ScrollTop {...props} >
          <Fab size="small" aria-label="scroll back to top"  >
            <KeyboardArrowUpIcon  />
          </Fab>
        </ScrollTop>
      </React.Fragment>

     
    </Router>
  );
}
