import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import YoutubeLatest from '../components/youtubeLatest';
import YouTubeSubs from '../components/yotubeSubs';
import PythonCourse from '../components/pythonCourse';

const Home = () => {
  const [scrollY, setScrollY] = useState(0);

  // Handle scroll event
  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Calculate styles dynamically based on scroll position
  const headerStyle = {
    transform: `scale(${Math.max(0.5, 1 - scrollY / 300)})`, // Shrinks gradually
    opacity: `${Math.max(0, 1 - scrollY / 150)}`, // Fades out gradually
    height: `${Math.max(50, 150 - scrollY)}px`, // Shrinks height gradually
    transition: 'transform 0.1s, opacity 0.1s, height 0.1s', // Smooth animations
  };

  return (
    <>
      <Container maxWidth="xl">
        <div className="w-full">
          {/* Header Section */}
          <div
            className="headhid w-full flex flex-col items-center text-center justify-center fixed top-20 left-0 "
            style={headerStyle}
          >
            <div>
              <img
                className="p-2 rounded-full border-2 border-blue-600"
                src="./logo.png"
                alt="Logo"
                style={{
                  width: `${Math.max(40, 100 - scrollY / 3)}px`, // Shrinks logo gradually
                  height: `${Math.max(40, 100 - scrollY / 3)}px`,
                  transition: 'width 0.1s, height 0.1s', // Smooth logo size change
                }}
              />
            </div>
            <div
              style={{
                opacity: `${Math.max(0, 1 - scrollY / 150)}`, // Gradually hides text
                transition: 'opacity 0.1s', // Smooth text fade
              }}
            >
              <h1 className="text-2xl font-bold">Welcome to OfficialKasun!</h1>
              
            </div>
            
          </div>

          {/* Content Section */}
          <div className="mt-[150px] flex flex-col gap-10 w-full">
           
            <div className='flex flex-wrap-reverse w-full justify-center gap-10'>
            <YouTubeSubs />
            <PythonCourse />
            </div>
            <YoutubeLatest />
           
          </div>
        </div>
      </Container>
    </>
  );
};

export default Home;
