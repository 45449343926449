import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import SettingsBrightnessIcon from "@mui/icons-material/SettingsBrightness";
import MenuIcon from '@mui/icons-material/Menu';
import SchoolIcon from '@mui/icons-material/School';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export default function Navbar() {
  const [anchorElMainMenu, setAnchorElMainMenu] = useState(null);
  const [anchorElLoginMenu, setAnchorElLoginMenu] = useState(null);

  const openMainMenu = Boolean(anchorElMainMenu);
  const openLoginMenu = Boolean(anchorElLoginMenu);

  const handleMainMenuClick = (event) => {
    setAnchorElMainMenu(event.currentTarget);
  };

  const handleLoginMenuClick = (event) => {
    setAnchorElLoginMenu(event.currentTarget);
  };

  const handleMainMenuClose = () => {
    setAnchorElMainMenu(null);
  };

  const handleLoginMenuClose = () => {
    setAnchorElLoginMenu(null);
  };

  // Theme handling
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") || "system"
  );

  useEffect(() => {
    const applyTheme = (selectedTheme) => {
      if (selectedTheme === "system") {
        const systemPrefersDark = window.matchMedia(
          "(prefers-color-scheme: dark)"
        ).matches;
        document.body.setAttribute(
          "data-theme",
          systemPrefersDark ? "dark" : "light"
        );
      } else {
        document.body.setAttribute("data-theme", selectedTheme);
      }
      localStorage.setItem("theme", selectedTheme);
    };
    applyTheme(theme);

    // Listen for system theme changes
    const systemThemeListener = window.matchMedia(
      "(prefers-color-scheme: dark)"
    );
    const handleSystemThemeChange = () => {
      if (theme === "system") {
        applyTheme("system");
      }
    };
    systemThemeListener.addEventListener("change", handleSystemThemeChange);

    return () => {
      systemThemeListener.removeEventListener(
        "change",
        handleSystemThemeChange
      );
    };
  }, [theme]);

  const toggleTheme = () => {
    if (theme === "light") {
      setTheme("dark");
    } else if (theme === "dark") {
      setTheme("light");
    } else {
      setTheme("light");
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar className="bg-blue-800 py-4 shadow-xl">
          {/* Main Menu */}
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            id="main-menu-button"
            aria-controls={openMainMenu ? "main-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openMainMenu ? "true" : undefined}
            onClick={handleMainMenuClick}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="main-menu"
            aria-labelledby="main-menu-button"
            anchorEl={anchorElMainMenu}
            open={openMainMenu}
            onClose={handleMainMenuClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem onClick={handleMainMenuClose}>
              <Link style={{ textDecoration: "none", color: "inherit" }} to="/">
                Home
              </Link>
            </MenuItem>
            <MenuItem onClick={handleMainMenuClose}>
              <Link
                style={{ textDecoration: "none", color: "inherit" }}
                to="/about"
              >
                About Me
              </Link>
            </MenuItem>
            <MenuItem onClick={handleMainMenuClose}>Projects</MenuItem>
            <MenuItem onClick={handleMainMenuClose}>Services</MenuItem>
          </Menu>

          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Link style={{ textDecoration: "none", color: "inherit" }} to="/">
              OFFICIALKASUN
            </Link>
            <small> v3.0</small>
          </Typography>

          {/* Login Menu */}
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            id="login-menu-button"
            aria-controls={openLoginMenu ? "login-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openLoginMenu ? "true" : undefined}
            onClick={handleLoginMenuClick}
          >
            <span className="text-base font-bold hidden md:block">Login</span>{" "}
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="login-menu"
            aria-labelledby="login-menu-button"
            anchorEl={anchorElLoginMenu}
            open={openLoginMenu}
            onClose={handleLoginMenuClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
           <MenuItem onClick={handleLoginMenuClose}>
              <Link
                style={{ textDecoration: 'none', color: 'inherit' }}
                to="https://client.officialkasun.com"
              >
                <ManageAccountsIcon /> Client Login
              </Link>
            </MenuItem>
            <MenuItem onClick={handleLoginMenuClose}>
              <Link
                style={{ textDecoration: 'none', color: 'inherit' }}
                to="https://learn.prolearners.lk"
              >
                <SchoolIcon /> Student Login
              </Link>
            </MenuItem>
          </Menu>

          {/* Theme Toggle Button */}
          <div className="fixed flex md:relative shadow-lg md:shadow-inherit hover:bg-base-100 md:hover:bg-inherit bottom-4 md:bottom-0 bg-base-300 text-base-content md:text-inherit md:bg-inherit justify-center items-center text-center p-1 m-0 rounded-full h-10 w-10">
          <IconButton
            size="large"
            color="inherit"
            // edge="start"
            aria-label="toggle theme"
            onClick={toggleTheme}
            
          >
            {theme === "light" && <LightModeIcon />}
            {theme === "dark" && <DarkModeIcon />}
            {theme === "system" && <SettingsBrightnessIcon />}
          </IconButton>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
