import React, { useEffect, useState } from 'react';
import axios from 'axios';

const YouTubeSubs = () => {
  const [subscriberCount, setSubscriberCount] = useState(null);
  const [channelName, setChannelName] = useState('');
  const [profileImage, setProfileImage] = useState('');


  const CHANNEL_ID = "UCfVL8l-mQpsaViwoNtVlihw"; // OfficialKasun channel ID

  useEffect(() => {
    const options = {
      method: 'GET',
      url: 'https://youtube138.p.rapidapi.com/channel/details',
      params: {
        id: CHANNEL_ID,
        hl: 'en',
        gl: 'US',
      },
      headers: {
        'x-rapidapi-key': '03ca7885aamsh16ad2617c9f6479p185e46jsn5e1f0adaf2dd',
        'x-rapidapi-host': 'youtube138.p.rapidapi.com',
      },
    };

    const fetchChannelData = async () => {

        const defaultData = {
            channelName: 'Kasun Madhusanka Jayasinghe',
            subscriberCount: '500+',
            profileImage: 'https://yt3.googleusercontent.com/X6bn6YxHJg4qAhAaVphWfvLsR6cp-cjMominRLmRYDCCZJM2MsYpvPFUmLbinDUcsPahvwfmDQ=s900-c-k-c0x00ffffff-no-rj',
          };
      try {
        const response = await axios.request(options);
        const data = response.data;

        if (data) {
          setSubscriberCount(data.stats.subscribers);
          setChannelName(data.title);
          setProfileImage(data.avatar[0].url);
        }
      } catch (error) {
  
        
        const options = {
            method: 'GET',
            url: 'https://youtube-v31.p.rapidapi.com/channels',
            params: {
              part: 'snippet,statistics',
              id: CHANNEL_ID
            },
            headers: {
              'x-rapidapi-key': '03ca7885aamsh16ad2617c9f6479p185e46jsn5e1f0adaf2dd',
              'x-rapidapi-host': 'youtube-v31.p.rapidapi.com'
            }
          };
          
          try {
              const response = await axios.request(options);
              setSubscriberCount(response.data.items[0].statistics.subscriberCount);
              setChannelName(response.data.items[0].snippet.title);
              setProfileImage(response.data.items[0].snippet.thumbnails.default.url);
          } catch (error) {
              
            setSubscriberCount(defaultData.subscriberCount);
            setChannelName(defaultData.channelName);
            setProfileImage(defaultData.profileImage);
            console.error('Error fetching YouTube data:', error);
          }
           
          
            
        
      
      }
    };

    fetchChannelData();
  }, []);

  return (
    <div className="max-w-xs w-96 mx-auto bg-base-100 rounded-lg shadow-lg overflow-hidden p-6 space-y-4 hover:translate-y-[10px] transition-all duration-700">
      <div className="flex items-center justify-center">
        {profileImage ? (
          <img
            src={profileImage}
            alt="Profile"
            className="w-24 h-24 rounded-full object-cover border-4 border-indigo-600"
          />
        ) : (
          <div className="skeleton w-24 h-24 rounded-full bg-base-300 animate-pulse" />
        )}
      </div>
      <div className="text-center">
        <h2 className="text-xl font-semibold text-base-content">{channelName}</h2>
        <p className="text-gray-500 mt-2">Subscribers: {subscriberCount}</p>
      </div>
      <div className="flex justify-center">
        <a href='https://www.youtube.com/channel/UCfVL8l-mQpsaViwoNtVlihw?sub_confirmation=1' target='_blank' className="btn mt-4 bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition duration-200">
          View Channel
        </a>
      </div>
    </div>
  );
};

export default YouTubeSubs;
