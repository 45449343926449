import React from 'react';
import {
  Container,
  Typography,
  Paper,
  Grid,
  Box,
  LinearProgress,
  useTheme,
  Card,
  CardContent,
  CardHeader
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CodeIcon from '@mui/icons-material/Code';
import WebIcon from '@mui/icons-material/Web';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';

const SkillBar = ({ name, value, color }) => (
  <Box sx={{ mb: 2 }}>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
      <Typography variant="body1" sx={{ color: 'text.secondary' }}>
        {name}
      </Typography>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        {value}%
      </Typography>
    </Box>
    <LinearProgress
      variant="determinate"
      value={value}
      sx={{
        height: 8,
        borderRadius: 4,
        backgroundColor: `${color}20`,
        '& .MuiLinearProgress-bar': {
          backgroundColor: color,
          borderRadius: 4,
        }
      }}
    />
  </Box>
);

const About = () => {
  const theme = useTheme();

  const languageSkills = [
    { name: 'Python', value: 90 },
    { name: 'PHP', value: 85 },
    { name: 'C#', value: 80 },
    { name: 'C++', value: 75 },
    { name: 'Java', value: 85 },
    { name: 'JavaScript', value: 95 },
    { name: 'TypeScript', value: 88 }
  ];

  const frameworkSkills = [
    { name: 'ReactJS', value: 92 },
    { name: 'NodeJS', value: 88 },
    { name: 'NextJS', value: 85 },
    { name: 'Laravel', value: 80 },
    { name: 'ExpressJS', value: 85 },
    { name: 'TailwindCSS', value: 90 },
    { name: 'MaterialUI', value: 85 }
  ];

  return (
    <Box className="min-h-screen bg-gradient-to-br from-base-200 to-base-100 py-4 rounded-lg shadow-lg">
      <Container maxWidth="xl">
        {/* Header */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 4 }}>
          <InfoIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />
          <Typography
            variant="h3"
            sx={{
              fontWeight: 'bold',
              background: 'linear-gradient(45deg, #1976d2 30%, #3f51b5 90%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent'
            }}
          >
            About Me
          </Typography>
        </Box>

        {/* Main Content */}
        <Card  sx={{ mb: 4, boxShadow: 3 }}>
          <CardContent className='bg-base-100 text-base-content' >
            <Typography variant="body1" sx={{ mb: 2 }}>
              Hi, I'm <Box component="span"  sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
                Kasun Madhusanka Jayasinghe
              </Box>, a passionate and versatile software developer with a focus on web, mobile, and software development.
              I am currently pursuing a BSc (Hons.) in Computing Science in Software Engineering at Kingston University, UK.
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              I hold a Pearson BTEC Level 5 HND in Computing (Software Engineering), and have developed a strong skill set in various programming 
              languages and frameworks. My expertise spans across multiple technologies and platforms, enabling me 
              to create comprehensive solutions for diverse projects.
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              As a content creator on YouTube, I share IT-related knowledge and tutorials to help others 
              navigate the ever-evolving tech landscape. Also, I'm a freelancing lecturer at PRO LEARNERS LK Online Educational Platform since 2019.
            </Typography>

            <p variant="body1" className='bg-yellow-300 p-3 rounded-md text-red-600 font-bold'>
            The percentages mentioned below are based on my personal practices and reflect the languages and frameworks I most frequently use. These calculations are derived from the details of the projects I have worked on.
            </p>
          </CardContent>
        </Card>

        {/* Skills Section */}
        <Grid container spacing={4} sx={{ mb: 4 }} >
          {/* Programming Languages */}
          <Grid  item xs={12} md={6}  >
            <Card sx={{ boxShadow: 3 }}  >
              <CardHeader
                avatar={<CodeIcon color="primary" />}
                title={
                  <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                    Programming Languages
                  </Typography>
                }
              />
              <CardContent >
                {languageSkills.map((skill) => (
                  <SkillBar  
                    key={skill.name}
                    name={skill.name}
                    value={skill.value}
                    color={theme.palette.primary.main}
                  />
                ))}
              </CardContent>
            </Card>
          </Grid>

          {/* Frameworks */}
          <Grid item xs={12} md={6}>
            <Card sx={{ boxShadow: 3 }}>
              <CardHeader
                avatar={<WebIcon color="primary" />}
                title={
                  <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                    Frameworks & Tools
                  </Typography>
                }
              />
              <CardContent>
                {frameworkSkills.map((skill) => (
                  <SkillBar
                    key={skill.name}
                    name={skill.name}
                    value={skill.value}
                    color={theme.palette.secondary.main}
                  />
                ))}
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* Mobile Development */}
        <Card sx={{ boxShadow: 3 }} className='p-0.5' >
          <CardHeader className='bg-base-100 text-base-content'
            avatar={<PhoneIphoneIcon color="primary" />}
            title={
              <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                Mobile Development
              </Typography>
            }
          />
          <CardContent className='bg-base-100 text-base-content'>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Paper
                  sx={{
                    p: 3,
                    background: 'linear-gradient(145deg, #e3f2fd 0%, #bbdefb 100%)',
                    borderRadius: 2
                  }}
                >
                  <Typography variant="h6" sx={{ color: theme.palette.primary.main, mb: 1, fontWeight: 'bold' }}>
                    Android Development
                  </Typography>
                  <Typography className='min-h-20'>
                    Experienced in building native Android applications using Java and Kotlin,
                    following Material Design principles and best practices.
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper
                  sx={{
                    p: 3,
                    background: 'linear-gradient(145deg, #e8eaf6 0%, #c5cae9 100%)',
                    borderRadius: 2
                  }}
                >
                  <Typography variant="h6" sx={{ color: theme.palette.secondary.main, mb: 1, fontWeight: 'bold' }}>
                    iOS Development
                  </Typography>
                  <Typography className='min-h-20'>
                    Proficient in developing iOS applications using Swift and SwiftUI,
                    creating seamless user experiences for Apple devices.
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default About;